import React from 'react'
import { DateTime } from 'luxon'

import { localizedPath } from 'components/language/helpers'

import { Blocks } from 'components/Blocks'
import HtmlParser from 'components/HtmlParser'
import { Header } from 'components/Header'
import Seo from 'components/seo'
import Aside from './components/Aside'
import Navigation from './components/Navigation'
import { BlockCTA } from 'components/Header/components/BlockCTA'
import InfoSection from '../../../UI/InfoSection';

import * as cls from './articles.module.scss'

import AttentionIcon from '~/assets/images/attention.svg';
import QuestionIcon from '~/assets/images/question.svg';

const AboutPage = ({ pageContext: { pageData } }) => {
  const service = pageData.service.data.attributes
  const navigationList = pageData.contentBlocks?.map((block) => block.title)

  return (
    <>
      <Seo title={pageData.title} meta={pageData.seo} />
      <Header
        h1={pageData.header.h1}
        h2={pageData.header.h2}
        breadcrumbs={[
          { title: service.title, url: localizedPath(pageData.locale, service.slug) },
          { title: pageData.title },
        ]}
        button={pageData.header.button}
        buttonFlatStyle
        rightBlock={<BlockCTA />}
        isWide
      />

      <div className={cls.article__body}>
        <div className="container">
          <div className={cls.article__body_inner}>
            <div className={cls.article__content}>

              <div className={cls.article__date}>
                <p className={cls.article__date_text}>
                  {pageData?.user?.data?.attributes?.name}
                </p>
                <p className={`${cls.article__date_text} ${cls.calendar}`}>
                  Stand: <time>{DateTime.fromISO(pageData?.updatedAt).toFormat('dd.MM.yyyy')}</time>
                </p>
                <p className={`${cls.article__date_text} ${cls.time}`}>
                  {pageData?.readTime} Minuten
                </p>
              </div>

              {
                pageData?.blockInfoAtBeginning
                && (
                  <InfoSection
                    isContainer={false}
                    title={pageData?.blockInfoAtBeginning?.title}
                    text={pageData?.blockInfoAtBeginning?.text}
                    flagIcon={<AttentionIcon className={cls.flag_icon} />} />
                )
              }

              <Navigation items={navigationList} />

              {
                pageData.contentBlocks?.map((block, index) =>
                  <section key={index} id={`block${index}`} className="compensation">
                    <h2 className={`main__subtitle ${cls.article__subtitle}`}>
                      {block.title}
                    </h2>
                    <div className="main__text">
                      <HtmlParser htmlString={block.content} />
                    </div>
                  </section>
                )
              }

              {
                pageData?.blockInfoAtEnd
                && (
                  <InfoSection
                    isContainer={false}
                    title={pageData?.blockInfoAtEnd?.title}
                    text={pageData?.blockInfoAtEnd?.text}
                    flagIcon={<QuestionIcon className={cls.flag_icon} />}
                    background="var(--yellowLight)" />
                )
              }
            </div>

            <Blocks items={pageData.blocks} />

            <Aside navigationList={navigationList} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutPage
