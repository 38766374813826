import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Navigation from '../Navigation'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

import * as cls from './aside.module.scss'

import FacebookIcon from 'assets/images/icons/facebook.svg'
import TwitterIcon from 'assets/images/icons/twitter.svg'
import WhatsAppIcon from 'assets/images/icons/whatsapp.svg'
import MailIcon from 'assets/images/icons/mail.svg'

const Aside = ({ navigationList }) => {
  const isBrowser = typeof window !== 'undefined'
  const shareUrl = isBrowser ? window.location.href : ''

  return (
    <aside className={cls.article__aside}>

      <Navigation items={navigationList} />

      <div className={`${cls.social} ${cls.bg}`}>
        <h3 className="main__subtitle">
          Teilen
        </h3>

        <div className={cls.social__links}>
          <FacebookShareButton url={shareUrl} className={cls.social__link}>
            <FacebookIcon />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} className={cls.social__link}>
            <TwitterIcon />
          </TwitterShareButton>
          <WhatsappShareButton url={shareUrl} className={cls.social__link}>
            <WhatsAppIcon />
          </WhatsappShareButton>
          <EmailShareButton url={shareUrl} className={cls.social__link}>
            <MailIcon />
          </EmailShareButton>
        </div>
      </div>

      <div className={`${cls.article__aside_form} ${cls.bg}`}>
        <h3 className="main__subtitle">
          anyright Newsletter
        </h3>

        <form className="form">

          <input className="input" type="email" placeholder="Email address" />

          <label className={cls.form_confirm}>
            <input className={cls.form_checkbox} type="checkbox" />
            <span className={cls.form_check_square}></span>
            <p className="main__text">
              Ich bin damit einverstanden kostenlose E-Mail Newsletters von anyright zu aktuellen Themen zu erhalten.
            </p>
          </label>

          <button className={cls.form_submit} type="submit">
            Subscribe
          </button>

        </form>
      </div>

      <div className={`other_articles ${cls.bg}`}>
        <h3 className="main__subtitle">
          Neueste Beiträge
        </h3>

        <div className={cls.other_article}>
          <StaticImage
            className={cls.other_article_img}
            src="../../../../../assets/images/otherArticle_img2.jpg"
            alt="Article image"
          />
          <p className={cls.other_article_date}>
            MÄRZ 01 2021
          </p>
          <h5 className={cls.other_article_title}>
            Entdecke die innovativste Plattform für Gruppenreisen: JoinMyTrip
          </h5>
          <p className={cls.other_article_desc}>
            JoinMyTrip ist eine Plattform für private Gruppenreisen. Auf JoinMyTrip planen und teilen Reisende die spannendsten Erlebnisse des Jahres: ihre Reisen. Diese Reisen sind so besonders, weil sie mit anderen erlebt werden. Lies in diesem Artikel mehr zu dem Konzept.
          </p>
        </div>

        <div className={cls.other_article}>
          <StaticImage
            className={cls.other_article_img}
            src="../../../../../assets/images/otherArticle_img2.jpg"
            alt="Article image"
          />
          <p className={cls.other_article_date}>
            MÄRZ 01 2021
          </p>
          <h5 className={cls.other_article_title}>
            Entdecke die innovativste Plattform für Gruppenreisen: JoinMyTrip
          </h5>
          <p className={cls.other_article_desc}>
            JoinMyTrip ist eine Plattform für private Gruppenreisen. Auf JoinMyTrip planen und teilen Reisende die spannendsten Erlebnisse des Jahres: ihre Reisen. Diese Reisen sind so besonders, weil sie mit anderen erlebt werden. Lies in diesem Artikel mehr zu dem Konzept.
          </p>
        </div>

        <div className={cls.other_article}>
          <StaticImage
            className={cls.other_article_img}
            src="../../../../../assets/images/otherArticle_img2.jpg"
            alt="Article image"
          />
          <p className={cls.other_article_date}>
            MÄRZ 01 2021
          </p>
          <h5 className={cls.other_article_title}>
            Entdecke die innovativste Plattform für Gruppenreisen: JoinMyTrip
          </h5>
          <p className={cls.other_article_desc}>
            JoinMyTrip ist eine Plattform für private Gruppenreisen. Auf JoinMyTrip planen und teilen Reisende die spannendsten Erlebnisse des Jahres: ihre Reisen. Diese Reisen sind so besonders, weil sie mit anderen erlebt werden. Lies in diesem Artikel mehr zu dem Konzept.
          </p>
        </div>

      </div>

    </aside>
  )
}

export default Aside
