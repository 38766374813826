// extracted by mini-css-extract-plugin
export var bg = "aside-module--bg--24BCG";
export var article__aside = "aside-module--article__aside--x6YnG";
export var social = "aside-module--social--q+xQy";
export var social__links = "aside-module--social__links--aFiCz";
export var social__link = "aside-module--social__link--KMYgu";
export var article__aside_form = "aside-module--article__aside_form--Xlwaq";
export var form_confirm = "aside-module--form_confirm--WxSAy";
export var form_checkbox = "aside-module--form_checkbox--js4Nw";
export var form_check_square = "aside-module--form_check_square--ljXF-";
export var form_submit = "aside-module--form_submit--7TPMV";
export var other_article = "aside-module--other_article--YQSbd";
export var other_article_img = "aside-module--other_article_img--EzxN9";
export var other_article_date = "aside-module--other_article_date--x-aNZ";
export var other_article_title = "aside-module--other_article_title--F8GXv";
export var other_article_desc = "aside-module--other_article_desc--NTFi9";