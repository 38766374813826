import React from 'react'

import * as cls from './navigation.module.scss'

const Navigation = ({ items }) => {
  if (!items) {
    return <></>
  }

  return (
    <section className={cls.article__nav}>
      <h3 className="main__subtitle">
        Navigation
      </h3>
      <ol className="article__nav_list">
        {
          items.map((title, index) =>
            <li key={index} className="article__nav_listItem">
              <a className="article__nav_listLink" href={`#block${index}`}>
                {title}
              </a>
            </li>
          )
        }
      </ol>
    </section>
  )
}

export default Navigation
