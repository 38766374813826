import React, {useContext} from 'react'
import SiteContext from 'helpers/siteContext'

import ArrowRight from 'assets/images/arrow-right.svg'
import TrustPilotLink from 'components/TrustPilotLink'
import GoogleReviews from 'assets/images/google-reviews.svg'

import * as cls from './blockCTA.module.scss'

export function BlockCTA() {
  const siteData = useContext(SiteContext)
  const data = siteData.blockCTA

  if (!data) {
    return <></>
  }

  return (
    <div className={`query ${cls.query}`}>
      <h3>{data.title}</h3>

      {
        data.items &&
        <ul>
          {
            data.items.map((item, index) =>
              <li key={index}>
                <a href={item.url}>
                  {item.title}
                  <ArrowRight />
                </a>
              </li>
            )
          }
        </ul>
      }

      <div className={cls.query_bot}>
        <TrustPilotLink />
        <a href="/#">
          <GoogleReviews />
        </a>
      </div>
    </div>
  )
}
