const languageConfig = require('./config')

const localizedPath = (language, path) => {
  const pathPrepared = path ? `/${path}/` : '/'

  return (!languageConfig.prefixDefault && (language === languageConfig.defaultLanguage))
    ? pathPrepared
    : `/${language}${pathPrepared}`
}

module.exports = {
  config: languageConfig,
  localizedPath,
}
